import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActions } from '@mui/material';
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import SCarouselImages from "./SCarouselImages";

export default function SCard(props: any) {
  const theme = useTheme();

  return (
    <Card sx={{ width: "100%", position: "relative" }} elevation={props.elevation}>
        <Box>
          <SCarouselImages id={"TestCardCarousel-" + props.id} slides={[
            {img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
            {img: {src: "https://mui.com/static/images/cards/paella.jpg"}},
            {img: {src: "https://konyv.de/wp-content/uploads/2021/02/B844972-250x363.jpg"}},
            {img: {src: "https://media.cnn.com/api/v1/images/stellar/prod/211125112643-bart-the-bear-ii.jpg"}},
            {img: {src: "https://media.cnn.com/api/v1/images/stellar/prod/220309153016-02-rose-veiled-fairy-wrasse-discovery.jpg"}},
            {img: {src: "https://vista.hu/upload/15233/1523307803_kis-55.jpg"}},
          ]} />
        </Box>
        <CardContent
          sx={{
            position: "absolute",
            right: 0,
            borderRadius: 1,
            paddingY: 1,
            paddingX: 2,
            marginTop: "-30px",
            marginRight: 2,
            zIndex: 1,
            background: theme.palette.primary.main,
            color: "white"
          }}
        >
          sdfd
        </CardContent>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Subtitle
          </Typography>
        </CardContent>
      <CardActions>
        <Button size="small" color="primary">
          view
        </Button>
      </CardActions>
    </Card>
  );
}
