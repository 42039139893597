import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import SApp from "./components/SApp";
import reportWebVitals from './reportWebVitals';
import './index.css';
// import {huHU} from '@mui/x-date-pickers/locales';
// import {LocalizationProvider} from "@mui/x-date-pickers-pro";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 580,
      md: 800,
      lg: 1200,
      xl: 1920,
    }
  },
  palette: {
    text: {
      primary: '#424242',
      secondary: '#70757A',
      disabled: '#B5B6B7',
    },
    primary: {
      light: '#b5e7fc',
      main: '#21a2e6',
      dark: '#1C8FD2',
    },
    secondary: {
      light: '#8FD21C',
      main: '#68AD00',
      dark: '#207800',
    },
    info: {
      light: '#9ed8ff',
      main: '#7ecffe',
      dark: '#4fb5eb',
    },
    success: {
      light: '#B8F4C0',
      main: '#00c300',
      dark: '#007C00',
    },
    error: {
      light: '#f62640',
      main: '#e6213f',
      dark: '#d41438',
    },
    warning: {
      light: '#F5E127',
      main: '#EF9D2B',
      dark: '#DD7520',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  },
  shape: {
    borderRadius: 8
  },
  mixins: {
    toolbar: {
      minHeight: 64
    }
  },
})

theme.typography.h1 = {
  color: theme.palette.grey["900"],
  fontSize: theme.typography.pxToRem(28),
  lineHeight: '1.5',
  fontWeight: 500,
  letterSpacing: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(48),
    lineHeight: '1.33',
    fontWeight: 600,
    letterSpacing: 0,
  }
}
theme.typography.h2 = {
  color: theme.palette.grey["900"],
  fontSize: theme.typography.pxToRem(24),
  lineHeight: '1.5',
  fontWeight: 500,
  letterSpacing: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(28),
    lineHeight: '1.33',
    fontWeight: 600,
    letterSpacing: 0,
  }
}
theme.typography.h3 = {
  color: theme.palette.grey["900"],
  fontSize: theme.typography.pxToRem(16),
  lineHeight: '1.5',
  fontWeight: 500,
  letterSpacing: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: '1.33',
    fontWeight: 600,
    letterSpacing: 0,
  }
}
theme.typography.h4 = {
  color: theme.palette.grey["900"],
  fontSize: theme.typography.pxToRem(16),
  lineHeight: '1.5',
  fontWeight: 500,
  letterSpacing: 0.1,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: '1.33',
    fontWeight: 600,
    letterSpacing: 0,
  }
}
theme.typography.h5 = {
  color: theme.palette.grey["900"],
  fontSize: theme.typography.pxToRem(16),
  lineHeight: '1.5',
  fontWeight: 500,
  letterSpacing: 0.1,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '1.33',
    fontWeight: 600,
    letterSpacing: 0,
  }
}
theme.typography.h6 = {
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(15),
  lineHeight: '1.5',
  fontWeight: 400,
  letterSpacing: 0.2,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '1.33',
    letterSpacing: 0,
  }
}

theme.typography.body1 = {
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(14),
  lineHeight: '1.43',
  fontWeight: 400,
  letterSpacing: 0.2,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: '1.33',
    letterSpacing: 0,
  }
}
theme.typography.body2 = {
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(12),
  lineHeight: '1.33',
  fontWeight: 400,
  letterSpacing: 0.3,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '1.23',
    letterSpacing: 0,
  }
}

// theme.typography.subtitle1 = {
//   color: theme.palette.text.secondary,
//   fontSize: theme.typography.pxToRem(16),
//   [theme.breakpoints.up('md')]: {
//     fontSize: theme.typography.pxToRem(20),
//   }
// }
// theme.typography.subtitle2 = {
//   color: theme.palette.text.secondary,
//   fontSize: theme.typography.pxToRem(14),
//   [theme.breakpoints.up('md')]: {
//     fontSize: theme.typography.pxToRem(15),
//   }
// }

theme.components = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'div',
        h2: 'div',
        h3: 'div',
        h4: 'div',
        h5: 'div',
        h6: 'div',
        subtitle1: 'div',
        subtitle2: 'div',
        body1: 'span',
        body2: 'span',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ownerState}) => ({
        ...({
          borderRadius: "100px",
          textTransform: "none",
          paddingLeft: theme.typography.pxToRem(16),
          paddingRight: theme.typography.pxToRem(16),
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: theme.typography.pxToRem(15),
          lineHeight: theme.typography.pxToRem(18),
          fontWeight: 600,
          whiteSpace: 'nowrap',
        }),
        ...(ownerState.size === 'small' && {
          lineHeight: theme.typography.pxToRem(32),
        }),
        ...(ownerState.size === 'medium' && {
          lineHeight: theme.typography.pxToRem(36),
        }),
        ...(ownerState.size === 'large' && {
          lineHeight: theme.typography.pxToRem(40),
        }),
      })
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: theme.typography.pxToRem(theme.shape.borderRadius)
      }
    }
  },
  MuiPopover: {
    defaultProps: {
      container: rootElement,
    }
  },
  MuiPopper: {
    defaultProps: {
      container: rootElement,
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: theme.typography.pxToRem(48),
        padding: 0
      },
    }
  },
  // MuiInputLabel: {
  //   styleOverrides: {
  //     root: {
  //       lineHeight: 1,
  //       paddingTop: 0,
  //       height: 'auto',
  //     }
  //   }
  // }
}

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/*<LocalizationProvider localeText={huHU.components.MuiLocalizationProvider.defaultProps.localeText}>*/}
          <SApp />
        {/*</LocalizationProvider>*/}
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

reportWebVitals();
