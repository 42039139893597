const pagePatterns = [
  {
    name: 'home',
    pattern: '^/$'
  },
  {
    name: 'blogPostDetails',
    pattern: '^/test/[0-9]+$'
  },
  {
    name: 'layoutExample',
    pattern: '^/layoutExample$'
  }
]

export default pagePatterns