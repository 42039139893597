import * as React from 'react';
import Link from '@mui/material/Link';

export default function SLink() {
  return (
      <Link href="#" underline="hover">
        Link Example
      </Link>
  );
}
