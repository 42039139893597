import * as React from "react";
import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import SPopOverFullDialog from "../components/SPopOverFullDialog";
import {useState} from "react";
import Button from "@mui/material/Button";
import SMapStyle from "../components/SMapStyle";
import {Wrapper} from "@googlemaps/react-wrapper";
import {useTheme} from "@mui/material/styles";
import SCard from "../components/SCard";
import SCheckBoxes from "../components/SCheckBoxes";
import SRadioBoxes from "../components/SRadioBoxes";
import SSwitchBoxes from "../components/SSwitchBoxes";
import { Swiper, SwiperSlide } from 'swiper/react';
import TuneIcon from '@mui/icons-material/Tune';
import SDateRangePicker from "../components/SDateRangePicker";
import {DateRange} from "@mui/x-date-pickers-pro";
import {Dayjs} from "dayjs";
import PersonIcon from '@mui/icons-material/Person';
import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import SFilterButton from "../components/SFilterButton";

const mapApiKey = "AIzaSyD2Wa9QJ_tkkhZ9v96L4ImPr1bLzPYLJDM"
const mapId = "GoogleMap-1"
const lang = "hu"

const data = [
  {id: "1", title: "Test 1", label: "123 USD", position: {lat: -4.689574, lng: 55.391909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "2", title: "Test 2", label: "222 USD", position: {lat: -4.669574, lng: 55.381909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "3", title: "Test 3", label: "333.000 USD", position: {lat: -4.699574, lng: 55.401909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "4", title: "Test 4", label: "444.445 USD", position: {lat: -4.649574, lng: 55.411909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "5", title: "Test 5", label: "5.000 USD", position: {lat: -4.629574, lng: 55.421909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "6", title: "Test 6", label: "6.000 USD", position: {lat: -4.679574, lng: 55.431909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "7", title: "Test 7", label: "7.000 USD", position: {lat: -4.699574, lng: 55.441909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "8", title: "Test 8", label: "8.000 USD", position: {lat: -4.689574, lng: 55.451909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "9", title: "Test 9", label: "9.000 USD", position: {lat: -4.659574, lng: 55.461909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
  {id: "10", title: "Test 10", label: "10.000.000 USD", position: {lat: -4.669574, lng: 55.471909}, img: {src: "https://mui.com/static/images/cards/contemplative-reptile.jpg"}},
]

const connections = [
  {from: "1", to: "10"},
  {from: "1", to: "5"},
  {from: "2", to: "7"},
]

export default function LayoutExamplePage() {
  const theme = useTheme();

  const [filterText, setFilterText] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [googleMaps, setGoogleMaps] = useState(null)
  const [map, setMap] = useState(null)
  const [bounds, setBounds] = useState(null)
  const [currentItem, setCurrentItem] = useState("")
  const [mapMarkers, setMapMarkers] = useState([])
  const [mapLines, setMapLines] = useState([])
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [swiper, setSwiper] = useState(null)
  const [mapMode, setMapMode] = useState(false)
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([null, null]);
  const [listScrolled, setListScrolled] = useState(false)

  window.addEventListener('scroll', () => setListScrolled(window.scrollY > 130));

  const mapModeCarouselHeight = 300

  const mapBoundsPaddings = {
    left: 60,
    right: 60,
    top: 100,
    bottom: mapMode ? mapModeCarouselHeight : 30
  }

  const setMapBounds = () => {
    if (bounds === null || map === null || googleMaps === null) {
      return
    }
    // @ts-ignore
    map.fitBounds(bounds, mapBoundsPaddings)
  }

  const carouselSlideTo = (id: string) => {
    // @ts-ignore
    swiper.slideTo(filteredData.findIndex((item) => item.id === id))
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    if (width >= theme.breakpoints.values.md) {
      setMapMode(false)
    }
    setMapBounds()
  }, [mapMode, width]);

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const margin = (width < theme.breakpoints.values.sm) ? 1 : 2
  const marginPx = theme.spacing(margin)

  const selectItem = (id: string) => {
    carouselSlideTo(id)

    setTimeout(() => {
      mapMarkers.map(
        (marker: any) => {
          if (marker.id === id) {
            marker.element.classList.add("s-map-marker-active")
            marker.element.style.setProperty('--marker-color', theme.palette.primary.main);
          } else {
            marker.element.classList.remove("s-map-marker-active")
            marker.element.style.setProperty('--marker-color', theme.palette.common.white);
          }
        }
      )

      setCurrentItem(id)
    }, 0)
  }

  window.onload = () => {
    async function initMap() {
      await window.google.maps.importLibrary("marker");

      // @ts-ignore
      setGoogleMaps(window.google.maps)

      let bounds = new window.google.maps.LatLngBounds()

      let googleMap = new window.google.maps.Map(document.getElementById(mapId + "container") as HTMLElement, {
        mapId: mapId,
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_CENTER,
        },
        clickableIcons: false,
        gestureHandling: 'greedy',
        mapTypeControlOptions: {mapTypeIds: ["styled_map"]},
        mapTypeId: 'styled_map',
      });

      googleMap.mapTypes.set("styled_map", new window.google.maps.StyledMapType(SMapStyle));

      filteredData.map((item: any) => {
        bounds.extend(item.position)

        let div = document.createElement("div");
        div.className = "s-map-marker";
        div.textContent = item.label;

        let marker = new window.google.maps.marker.AdvancedMarkerElement({
          position: item.position,
          map: googleMap,
          content: div
        });

        marker.element.style.setProperty('--marker-shadow', theme.shadows["2"]);
        marker.element.style.setProperty('--marker-shadow-hover', theme.shadows["5"]);
        marker.element.style.setProperty('--marker-color', theme.palette.common.white);
        marker.element.style.setProperty('--marker-border-radius', theme.shape.borderRadius + 'px');
        marker.element.style.setProperty('--marker-font-size', theme.typography.pxToRem(theme.typography.fontSize));
        marker.element.style.setProperty('--marker-font-family', theme.typography.fontFamily ?? null);

        marker.id = item.id

        marker.addListener("click", () => {
          selectItem(item.id)
          if (!mapMode) {
            let element = document.getElementById(mapId + "ListItem" + item.id)
            if (element) {
              window.scrollTo({
                behavior: 'smooth',
                top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 80
              })
            }
          }
        });

        // @ts-ignore
        mapMarkers.push(marker)
        setMapMarkers(mapMarkers)
      })

      connections.map((connection) => {
        let fromItem = filteredData.find((item) => item.id === connection.from)
        let toItem = filteredData.find((item) => item.id === connection.to)

        if (fromItem && toItem) {
          let path = [fromItem.position, toItem.position]

          let line = new window.google.maps.Polyline({
            path: path,
            map: googleMap,
            strokeColor: theme.palette.warning.light,
            geodesic: true,
            strokeOpacity: 1.0,
            strokeWeight: 2,
          })

          // @ts-ignore
          line.from = fromItem.id; line.to = toItem.id

          // @ts-ignore
          mapLines.push(line)
          setMapLines(mapLines)
        }
      })

      // @ts-ignore
      setBounds(bounds)
      googleMap.fitBounds(bounds, mapBoundsPaddings)

      // @ts-ignore
      setMap(googleMap)
    }

    initMap()
  }

  const itemContent = (item: any, elevation: number) => (
    <SCard {...item} elevation={elevation} />
  )

  const filterByText = (text: string) => {
    setFilterText(text)

    let filtered = data.filter(
      item => item.title.toLowerCase().includes(
        text.toLowerCase()
      )
    )

    setFilteredData(filtered)

    setTimeout(() => {
      mapMarkers.map(
        (marker: any) => {
          if (filtered.find(item => item.id === marker.id)) {
            marker.element.classList.remove("s-map-marker-hidden")
          } else {
            marker.element.classList.add("s-map-marker-hidden")
          }
        }
      )

      mapLines.map(
        (line: any) => {
          if (filtered.find(item => item.id === line.from) && filtered.find(item => item.id === line.to)) {
            line.setVisible(true)
          } else {
            line.setVisible(false)
          }
        }
      )
    }, 0)
  }

  // @ts-ignore
  const carousel = <Swiper slidesPerView="auto" centeredSlides={true} effect="slide" onSwiper={(swiper) => setSwiper(swiper)} onSlideChange={
    (swiper) => {
      if (mapMode) {
        selectItem(filteredData[swiper.activeIndex ?? 0].id)
      }
    }
  }>
    {filteredData.map((item, index) => (
      <SwiperSlide key={index} virtualIndex={index} style={{background: "transparent", width: "360px"}}>
        {itemContent(item, 1)}
      </SwiperSlide>
    ))}
  </Swiper>

  const dateRangePickerAboveList = <SDateRangePicker id="s-date-range-filter" value={dateRange} setValue={setDateRange} />

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
    >
      <Box sx={{
        position: "fixed",
        right: 0,
        width: {
          xs: 1,
          md: "calc(100% - 400px)",
          lg: 2/3,
          xl: "calc(100% - 600px)",
        }
      }}>
        <Wrapper apiKey={mapApiKey} language={lang}>
          <Box
            id={mapId + "container"}
            sx={{
              width: {
                xs: "100%",
                md: "calc(100% - " + marginPx + ")"
              },
              height: {
                xs: "calc(100vh - " + theme.typography.pxToRem(theme.mixins.toolbar.minHeight as number) + ")",
                md: "calc(100vh - " + theme.typography.pxToRem(theme.mixins.toolbar.minHeight as number) + " - 2 * " + marginPx + ")"
              },
              top: {
                xs: 0,
                md: marginPx
              },
              borderRadius: {
                xs: "0",
                md: theme.typography.pxToRem(8)
              }
            }}
          />
        </Wrapper>
      </Box>
      <Box sx={{
        position: {
          xs: "absolute",
          md: "initial"
        },
        width: {
          xs: 1,
          md: "400px",
          lg: 1/3,
          xl: "600px",
        },
        marginTop: {
          xs: theme.typography.pxToRem(48),
          md: 0
        },
        background: {
          xs: mapMode ? "transparent" : "#fff",
          md: "transparent"
        },
        height: {
          xs: mapMode ? theme.typography.pxToRem(mapModeCarouselHeight) : 'auto',
          md: 'auto'
        },
        bottom: {
          xs: mapMode ? '0' : 'unset',
          md: 'unset'
        },
        overflow: "hidden",
      }}>
        <Box sx={{
          display: {
            xs: mapMode ? undefined : "none",
            md: "none"
          },
          paddingBottom: theme.typography.pxToRem(30)
        }}>
          {carousel}
        </Box>
        <Box
          sx={{
            display: {
              xs: mapMode ? "none" : "block",
              md: "block"
            }
          }}
        >
          <Box
            sx={{
              paddingTop: 2,
              paddingX: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              {dateRangePickerAboveList}
              <SPopOverFullDialog
                buttonIcon={<PersonIcon />}
                size="large"
                buttonText={'2'}
                title={'Vendégek'}
                content={
                  <Box sx={{paddingX: 2, paddingBottom: 2, paddingTop: 1, display: "flex", gap: 2, flexDirection: "column"}}>
                    <SCheckBoxes label="Checkboxes List Example" />
                    <SRadioBoxes label="Radio Boxes List Example" />
                    <SSwitchBoxes label="Switches" />
                  </Box>
                }
              />
            </Box>
            <TextField
              value={filterText}
              placeholder="Keresés"
              onChange={event => filterByText(event.target.value.toLowerCase())}
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon fontSize="small" /></InputAdornment>,
                endAdornment: <InputAdornment position="end" hidden={filterText === ''}>
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={event => filterByText('')}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>,
              }}
            />
          </Box>
          <Stack sx={{
            paddingBottom: 2
          }}>
            {filteredData.map((item, index) => (
              <Box sx={{marginX: margin, marginTop: margin}} key={index} id={mapId + "ListItem" + item.id} onMouseOver={() => selectItem(item.id)}>
                {itemContent(item, currentItem === item.id ? 5 : 1)}
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>

      <Box
        sx={{
          position: "fixed",
          width: {
            xs: "100%",
            md: "calc(100% - 400px - " + theme.spacing(margin) + ")",
            lg: "calc(100% - (100% / 3) - " + theme.spacing(margin) + ")",
            xl: "calc(100% - 600px - " + theme.spacing(margin) + ")",
          },
          top: {
            xs: theme.typography.pxToRem(theme.mixins.toolbar.minHeight as number),
            md: "calc(" + theme.typography.pxToRem(theme.mixins.toolbar.minHeight as number) + " + " + marginPx + ")"
          },
          left: {
            xs: 0,
            md: "400px",
            lg: "calc(100% / 3)",
            xl: "600px",
          },
          padding: 2,
          paddingBottom: 0,
          background: {
            xs: mapMode ? "transparent" : "#fff",
            md: "transparent"
          }
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons={false}
          sx={{width: '100%'}}
        >

          <Stack direction="row" gap={1}>

            <SFilterButton
              hidden={mapMode || !listScrolled}
              text="Dátumok"
              onClick={() => {
                window.scrollTo({
                  behavior: 'smooth',
                  top: 0
                })

                // let element = document.getElementById('s-date-range-filter-start-input')
                //
                // if (!element) {
                //   return
                // }
                //
                // element.focus();
                //
                // element.dispatchEvent(new Event("click", { bubbles: false, cancelable: true }))
                //
                // let e = document.createEvent('Event');
                // e.initEvent("click", true, true);
                // element.dispatchEvent(e);
              }}
              startIcon={<TuneIcon />}
              dropDownArrowIcon={false}
            />

            <SFilterButton
              hidden={mapMode || !listScrolled}
              text="Vendégek"
              onClick={() => {
                window.scrollTo({
                  behavior: 'smooth',
                  top: 0
                })
              }}
              startIcon={<TuneIcon />}
              dropDownArrowIcon={false}
            />

            <SPopOverFullDialog
              hidden={!mapMode}
              buttonText={'Dátumok'}
              title={'Dátumok'}
              content={
                <Box sx={{paddingX: 2, paddingBottom: 2, paddingTop: 1, display: "flex", gap: 2, flexDirection: "column"}}>
                  <SDateRangePicker id="test2" value={dateRange} setValue={setDateRange} />
                </Box>
              }
            />

            <SPopOverFullDialog
              hidden={!mapMode}
              buttonText={'Vendégek'}
              title={'Vendégek'}
              content={
                <Box sx={{paddingX: 2, paddingBottom: 2, paddingTop: 1, display: "flex", gap: 2, flexDirection: "column"}}>
                  <SCheckBoxes label="Checkboxes List Example" />
                  <SRadioBoxes label="Radio Boxes List Example" />
                  <SSwitchBoxes label="Switches" />
                </Box>
              }
            />

            <SPopOverFullDialog
              buttonText={'Összes szűrő'}
              title={'Szürők'}
              buttonIcon={<TuneIcon />}
              content={
                <Box sx={{paddingX: 2, paddingBottom: 2, paddingTop: 1, display: "flex", gap: 2, flexDirection: "column"}}>
                  <SCheckBoxes label="Checkboxes List Example" />
                  <SRadioBoxes label="Radio Boxes List Example" />
                  <SSwitchBoxes label="Switches" />
                </Box>
              }
            />

            <SPopOverFullDialog
              buttonText={'Összes sziget'}
              title={'Szigetek'}
              content={
                <Box sx={{paddingX: 2, paddingBottom: 2, paddingTop: 1, display: "flex", gap: 2, flexDirection: "column"}}>
                  <SCheckBoxes label="Checkboxes List Example" />
                  <SRadioBoxes label="Radio Boxes List Example" />
                  <SSwitchBoxes label="Switches" />
                </Box>
              }
            />

            <SPopOverFullDialog
              buttonText={'Szállás típusa'}
              title={'Szállástípusok'}
              content={
                <Box sx={{paddingX: 2, paddingBottom: 2, paddingTop: 1, display: "flex", gap: 2, flexDirection: "column"}}>
                  <SCheckBoxes label="Checkboxes List Example" />
                  <SRadioBoxes label="Radio Boxes List Example" />
                  <SSwitchBoxes label="Switches" />
                </Box>
              }
            />

          </Stack>
        </Tabs>
      </Box>

      <Box
        hidden={width < theme.breakpoints.values.md}
        sx={{
          position: "fixed",
          width: {
            xs: "100%",
            md: "calc(100% - 400px - " + theme.spacing(margin) + ")",
            lg: "calc(100% - (100% / 3) - " + theme.spacing(margin) + ")",
            xl: "calc(100% - 600px - " + theme.spacing(margin) + ")",
          },
          bottom: theme.spacing(margin * 2),
          left: {
            xs: 0,
            md: "400px",
            lg: "calc(100% / 3)",
            xl: "600px",
          },
          padding: 2,
          paddingBottom: 0,
          background: {
            xs: mapMode ? "transparent" : "#fff",
            md: "transparent"
          },
          textAlign: 'center'
        }}
      >
        <SFilterButton
          text="Lista frissítése a térképpel"
          startIcon={<TuneIcon />}
          dropDownArrowIcon={false}
        />
      </Box>

      <Box
        sx={{
          display: {md: "none"},
          position: "fixed",
          top: "80px",
          right: "50px"
        }}
      >
        <Button onClick={() => setMapMode(!mapMode)}>map mode</Button>
      </Box>
    </Container>
  );
}