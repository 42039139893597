import * as React from 'react';
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DateRangeCalendar, LocalizationProvider, RangePosition} from "@mui/x-date-pickers-pro";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {ClickAwayListener} from "@mui/base/ClickAwayListener";
import SPopper from "./SPopper";
import SFullScreenDialog from "./SFullScreenDialog";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {InputAdornment, Paper} from '@mui/material';
import Divider from "@mui/material/Divider";
import DateRangeIcon from '@mui/icons-material/DateRange';

export default function SDateRangePicker(props: any)
{
  const theme = useTheme();

  const [rangePosition, setRangePosition] = React.useState<"start"|"end">("start");

  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [height, setHeight] = React.useState<number>(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width < theme.breakpoints.values.md;
  const calendarsCount = width < 630 ? 1 : 2

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [popOverAnchorEl, setPopOverAnchorEl] = React.useState<HTMLElement | null>(null);
  const popOverOpen = Boolean(popOverAnchorEl);
  const id = popOverOpen ? 'simple-popover' : undefined;

  const opened = dialogOpen || popOverOpen

  const open = () => {
    isMobile ? setDialogOpen(true) : setPopOverAnchorEl(document.getElementById(props.id))
  }
  const openEvent = (event: React.FocusEventHandler<HTMLElement>) => open()

  const close = () => {
    setPopOverAnchorEl(null)
    setDialogOpen(false)
  }
  const closeOnClickAway = () => {
    setPopOverAnchorEl(null)
  }

  const openFromStartInput = (event: any) => {
    setRangePosition("start")
    openEvent(event)
  }

  const openFromEndInput = (event: any) => {
    setRangePosition("end")
    openEvent(event)
  }

  const clearValues = () => {
    props.setValue([null, null])
  }

  const fromValue = props.value[0] ? props.value[0].format('YYYY-MM-DD') : ''
  const toValue = props.value[1] ? props.value[1].format('YYYY-MM-DD') : ''

  const calendar = <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DateRangeCalendar
      disableHighlightToday={true}
      rangePosition={rangePosition}
      defaultRangePosition={rangePosition}
      onRangePositionChange={(rangePosition: RangePosition) => setRangePosition(rangePosition)}
      calendars={calendarsCount}
      disablePast={true}
      showDaysOutsideCurrentMonth={true}
      value={props.value}
      onChange={(newValue) => props.setValue(newValue)}
      sx={{
        flexWrap: "wrap",
        justifyContent: "center"
      }}
    />
  </LocalizationProvider>

  const popupContent = <Box>
    {calendar}
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 2, paddingX: 2, paddingBottom: 2}}>
      <Button variant="text" onClick={clearValues}>töröl</Button>
      <Button variant="contained">tovább</Button>
    </Box>
  </Box>

  return (
    <div>
      <ClickAwayListener onClickAway={closeOnClickAway}>
        <div>
          <Paper variant="outlined">
            <Stack direction="row" alignItems="center" justifyContent="center" id={props.id}>
              <TextField
                id={props.id + '-start-input'}
                className="s-date-range-picker-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DateRangeIcon />
                    </InputAdornment>
                  ),
                }}
                //sx={{width: "calc((100% - 1px) / 2)"}}
                onFocus={openFromStartInput}
                onClick={openFromStartInput}
                inputProps={{
                  onFocus: openFromStartInput,
                  onClick: openFromStartInput,
                }}
                value={fromValue}
                placeholder="Érkezés"
                focused={opened && rangePosition === 'start'}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <TextField
                id={props.id + '-end-input'}
                className="s-date-range-picker-input"
                //sx={{width: "calc((100% - 1px) / 2)"}}
                onFocus={openFromEndInput}
                value={toValue}
                placeholder="Távozás"
                focused={opened && rangePosition === 'end'}
              />
            </Stack>
          </Paper>
          <SPopper id={id} open={popOverOpen} close={close} anchorEl={popOverAnchorEl} content={popupContent} marginTop={0.5} />
        </div>
      </ClickAwayListener>
      <SFullScreenDialog open={dialogOpen} close={close} title={props.title} content={popupContent} />
    </div>
  );
}
