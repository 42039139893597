import * as React from "react";
import SAppBar from "./SAppBar";
import SAppMain from "./SAppMain";
import {LicenseInfo} from "@mui/x-license-pro";
import licenceKey from "../LicenceKey";
import Box from "@mui/material/Box";

LicenseInfo.setLicenseKey(licenceKey);

export default function SApp() {
  return (
    <Box sx={{ display: 'block' }}>
      <SAppBar />
      <SAppMain />
    </Box>
  )
}